import styled from "styled-components"

import { IAddress, ILocation } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { ErrorIcon } from "src/ui/ErrorIcon/ErrorIcon"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function AdressWarning({
  address,
  location,
}: {
  address?: IAddress
  location?: ILocation
}) {
  const { t, langKeys } = useTranslate()

  if (address && location) {
    return <></>
  }

  return (
    <WarningBox>
      {!address && (
        <WarningBoxRow>
          <StyledErrorIcon state="warning" />
          <MText variant="body">
            {t(langKeys.home_settings_missing_address_info)}
          </MText>
        </WarningBoxRow>
      )}
      {!location && (
        <WarningBoxRow>
          <StyledErrorIcon state="warning" />
          <MText variant="body">
            {t(langKeys.home_settings_missing_location_info)}
          </MText>
        </WarningBoxRow>
      )}
    </WarningBox>
  )
}

const WarningBox = styled.div`
  display: grid;
  grid-gap: ${spacing.S};
`

const WarningBoxRow = styled.div`
  display: flex;
`

const StyledErrorIcon = styled(ErrorIcon)`
  margin-right: ${spacing.XS};
`
